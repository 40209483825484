import './AboutMe.css';
import cloudImg from './uiuccloud.JPG';

function AboutMe() {
  return (
    <div>
        <img src={cloudImg} />
    </div>
  );
}
export default AboutMe;