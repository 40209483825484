import './App.css';
import { render } from "react-dom";
import {
  BrowserRouter, Switch, Routes, Route, Link
} from 'react-router-dom';
import AboutMe from './AboutMe';
import Project from './Project';
import Home from './Home';
import NavBar from './NavBar';


function App() {
  return (
    <div>
      
      <div>
        <BrowserRouter>
          <NavBar />
          <div className='app-body'>
            <Routes>
              <Route path="/" element={<Home/>} />
              <Route path="/aboutme" element={<AboutMe/>} />
              <Route path="/projects" element={<Project/>} />
            </Routes>
          </div>
                
        </BrowserRouter>    
      </div>
      
    </div>
  );
}

export default App;
