import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {
  BrowserRouter, Switch, Routes, Route, Link, NavLink
} from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import './NavBar.css';

// const pages = ['Products', 'Pricing', 'Blog'];
// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

// // const NavBar = () => {
// //   const [anchorElNav, setAnchorElNav] = React.useState(null);
// //   const [anchorElUser, setAnchorElUser] = React.useState(null);

// //   const handleOpenNavMenu = (event) => {
// //     setAnchorElNav(event.currentTarget);
// //   };
// //   // const handleOpenUserMenu = (event) => {
// //   //   setAnchorElUser(event.currentTarget);
// //   // };

// //   const handleCloseNavMenu = () => {
// //     setAnchorElNav(null);
// //   };

// //   // const handleCloseUserMenu = () => {
// //   //   setAnchorElUser(null);
// //   // };

// //   return (
// //     <AppBar position="static">
// //       <Container maxWidth="xl">
// //         <Toolbar disableGutters>
// //           <Typography
// //             variant="h6"
// //             noWrap
// //             component="div"
// //             sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
// //           >
// //             LOGO
// //           </Typography>

// //           <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
// //             <IconButton
// //               size="large"
// //               aria-label="account of current user"
// //               aria-controls="menu-appbar"
// //               aria-haspopup="true"
// //               onClick={handleOpenNavMenu}
// //               color="inherit"
// //             >
// //               <MenuIcon />
// //             </IconButton>
// //             <Menu
// //               id="menu-appbar"
// //               anchorEl={anchorElNav}
// //               anchorOrigin={{
// //                 vertical: 'bottom',
// //                 horizontal: 'left',
// //               }}
// //               keepMounted
// //               transformOrigin={{
// //                 vertical: 'top',
// //                 horizontal: 'left',
// //               }}
// //               open={Boolean(anchorElNav)}
// //               onClose={handleCloseNavMenu}
// //               sx={{
// //                 display: { xs: 'block', md: 'none' },
// //               }}
// //             >
// //               {pages.map((page) => (
// //                 <MenuItem key={page} onClick={handleCloseNavMenu}>
// //                   <Typography textAlign="center">{page}</Typography>
// //                 </MenuItem>
// //               ))}
// //             </Menu>
// //           </Box>
// //           <Typography
// //             variant="h6"
// //             noWrap
// //             component="div"
// //             sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
// //           >
// //             LOGO
// //           </Typography>
// //           <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
// //             {pages.map((page) => (
// //               <Button
// //                 key={page}
// //                 onClick={handleCloseNavMenu}
// //                 sx={{ my: 2, color: 'white', display: 'block' }}
// //               >
// //                 {page}
// //               </Button>
// //             ))}
// //           </Box>
// //         </Toolbar>
// //       </Container>
// //     </AppBar>
// //   );
// // };
// // export default NavBar;


// function NavBar() {
//   return (
//     <>
//     <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
//       <CssBaseline />
//       <AppBar
//         position="static"
//         color="default"
//         elevation={0}
//         sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
//       >
//         <Toolbar sx={{ flexWrap: 'wrap' }}>
//           <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
//             Company name
//           </Typography>
//           <nav>
//             <Link
//               variant="button"
//               color="text.primary"
//               to="/"
//               sx={{ my: 1, mx: 1.5 }}
//             >
//               Features
//             </Link>
//             <Link
//               variant="button"
//               color="text.primary"
//               to="#"
//               sx={{ my: 1, mx: 1.5 }}
//             >
//               Enterprise
//             </Link>
//             <Link
//               variant="button"
//               color="text.primary"
//               to="#"
//               sx={{ my: 1, mx: 1.5 }}
//             >
//               Support
//             </Link>
//           </nav>
//           <Button href="#" variant="outlined" sx={{ my: 1, mx: 1.5 }}>
//             Login
//           </Button>
//         </Toolbar>
//       </AppBar>
//    </>
//   );
// }
// export default NavBar;

const NavBar = () => {
  return (
 <AppBar id='app-bar'>
      <nav> 
        <Box id='nav-box-title'>
          <Typography variant="h5" mt={1} style={{color:'black'}}>JaeEun Lee</Typography>
        </Box>
        <Box id='nav-box-page'>
          <Button id='nav-button' href="/">Home</Button>
          <Button id='nav-button' href="aboutme">About</Button>
          <Button id='nav-button' href="projects">Projects</Button>
        </Box>
        
       </nav>
       
    </AppBar>
);
};
export default NavBar;