function Project() {
  return (
    <div>
      <h1>Projects</h1>
      
      <div>
        <h3>Latency Insertion Model (LIM) Improvements</h3>
        <a href='https://drive.google.com/file/d/1v9eaKwz4juS8cDeqQt-7mUMJi2D5mtZf/view?usp=sharing'>Click</a>
      </div>
      
    </div>
  );
}
export default Project;