import './AboutMe.css';
import { Slider } from '@mui/material';
import { Rating } from '@mui/material';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
// import  from '@mui/icons-material';
import Typography from '@mui/material/Typography';

function AboutMe() {
  return (
    <div>
      <h2></h2>
      {/* <Slider disabled defaultValue={30}/> */}
      <div>
        <h3>Language</h3>
        <div>
          <h4>Korean</h4>
          <Rating defaultValue={7} precision={0.5} max={7} size='medium' readOnly />
          <h4>English</h4>
          <Rating defaultValue={5.5} precision={0.5} max={7} size='medium' readOnly />
          <h4>Chinese</h4>
          <Rating defaultValue={3} precision={0.5} max={7} size='medium' readOnly />
        </div>

      </div>

      
    </div>
  );
}
export default AboutMe;